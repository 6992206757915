.App {
  text-align: center;
}

.App-header {
  /* flex-direction: column; */
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}
